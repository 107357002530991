.content {
  height: 160px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 32px;

  .title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
